@import 'styles/includes.scss';

.RawHtml {
    $root: &;

    @extend %container-text;

    margin-bottom: 48px;

    @include media(l) {
        margin-bottom: 80px;
    }

    &__Facts {
        margin-top: 16px;
        padding: 8px 16px;
        background-color: $colorGrey200;

        @include media(l) {
            margin-top: 0;
            position: absolute;
            width: 212px;
            top: 42px;
            right: -220px;
        }

        h2,
        h3,
        h4,
        h5 {
            @extend %navigation-titles;
        }

        p,
        ul,
        ol {
            @extend %small-labels;
            margin-bottom: 16px;
        }

        a {
            color: $colorBlack70;
            text-decoration: underline;

            transition: color 200ms ease-out;
            &:hover {
                color: #000;
            }
        }

        li {
            @extend %body-copy-small;
            padding-left: 20px;
            position: relative;
            margin-bottom: 8px;
        }

        ol {
            list-style-position: inside;
            list-style-type: decimal;
            text-indent: -20px;
        }

        ol li {
            &::marker {
                color: $colorBlue;
                font-weight: bold;
                margin-right: 12px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        ul li {
            &:before {
                content: '';
                display: block;
                height: 6px;
                width: 6px;
                position: absolute;
                top: 8px;
                left: 0;
                background-color: $colorBlue;
            }
        }

        strong,
        b {
            font-weight: bold;
        }
        em,
        i {
            font-style: italic;
        }
    }

    &__Expand {
        @extend %button-titles;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        color: $colorBlack70;

        &:after {
            content: '';
            display: inline-block;
            height: 12px;
            background-size: contain;
            transition: transform 0.2s;
            transform: translateX(8px);
            background-image: url('#{$basepath}svg/icon-plus.svg');
            width: 12px;
        }
    }

    &--Inner {
        transform: none;
        padding: 0;
        margin: 0;
    }

    &__Html {
        #{$root}--Expandable & {
            max-height: 200px;
            overflow: hidden;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 80px;
                width: 100%;
                display: block;
                background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 2%,
                    #ffffff 99%
                );
            }
        }
    }

    &--InCampaignText {
        margin: 0;
        max-width: 900px;
        transform: none;

        h2 {
            margin-bottom: 12px;
        }

        h3,
        h4 {
            margin-bottom: 8px;
        }

        p {
            color: inherit;
        }

        p:not(:last-child) {
            margin-bottom: 24px;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}
